/**
* Transition to Height Auto
* Taken from https://github.com/maoberlehner/transition-to-height-auto-with-vue/blob/master/src/components/TransitionExpand.vue
*/
<script lang="ts">
import { h, Transition } from 'vue'

export default {
	setup (_, context) {
		return () => h(
			Transition,
			{
				name: 'expand',
				onAfterEnter: element => {
					element.style.height = 'auto'
				},
				onEnter: element => {
					const { width } = getComputedStyle(element)
					element.style.width = width
					element.style.position = 'absolute'
					element.style.visibility = 'hidden'
					element.style.height = 'auto'
					const { height } = getComputedStyle(element)
					element.style.width = null
					element.style.position = null
					element.style.visibility = null
					element.style.height = 0
					// Force repaint to make sure the
					// animation is triggered correctly.
					// eslint-disable-next-line no-unused-expressions
					getComputedStyle(element).height
					requestAnimationFrame(() => {
						element.style.height = height
					})
				},
				onLeave: element => {
					const { height } = getComputedStyle(element)
					element.style.height = height
					// Force repaint to make sure the
					// animation is triggered correctly.
					// eslint-disable-next-line no-unused-expressions
					getComputedStyle(element).height
					requestAnimationFrame(() => {
						element.style.height = 0
					})
				},
			},
			'default' in context.slots ? () => context.slots.default() : null,
		)
	},
}
</script>

<style scoped>
* {
    will-change: height;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
}
</style>

<style>
.expand-enter-active,
.expand-leave-active {
    transition: height 0.15s ease-in-out;
    overflow: hidden;
}
.expand-enter,
.expand-leave-to {
    height: 0;
}
</style>
